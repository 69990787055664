import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HomeBulletPoints from './HomeBulletPoints';
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';

//Images
import citiesImage from '../assets/images/CellPhoneTower.jpg';
import campusImage from '../assets/images/UniversityText.jpg';
import farmImage from '../assets/images/forrent.jpg';
import devices from '../assets/images/endtoend.png';
import getstarted from '../assets/images/getstarted.jpg';

function Home () {
    const navigate = useNavigate();
    const [topTextPosition, setTopTextPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const firstTextDiv = document.getElementById('topTextPosition');
            setTopTextPosition(firstTextDiv.getBoundingClientRect().top);
        }
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);
        handleScroll();
        return () => {window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleScroll)
        }
    });

    return(<>

        <Header onScroll={true} topTextPosition={topTextPosition} />
        <div className="hero">
        <div className="hero-overlay2" id='topTextPosition'>

            <h1a class="wordCarousel">
                <span>Making Technology Easier to </span>
              <div className="wordCarousel">
                <ul class="flip5">
                    <li>Adopt </li>
                    <li>Procure </li>
                    <li>Implement </li>
                    <li>Use </li>
                    <li>Upgrade </li>
                </ul>     
             </div> 
           </h1a>
            
       </div>

            <div className="hero-overlay" id='topTextPosition'>
                <h3> for Local Governments, Campuses, and Facilities.</h3>
                <h6> Remember separately buying your digital camera, music, mobile phone, iPod, and GPS navigtion before the iPhone changed things? Many organizations still buy software and technology individually and try unsuccessfully to piece it together later.  </h6>
                <h6> We solve this problem and more with ACADA&trade;: Where Smart Meets Simple.</h6>
                <Link to='/ACADA'><button>What is ACADA&trade;?</button></Link>
             
            </div>
       </div>
        
        
            <div className='fullScreen' style={{backgroundColor: 'white'}}>
            <div>
                <h3> ACADA&trade;:Your Answer for Easy to Use, Affordable, and Convienent Technology Adoption.</h3>
                <h6><b>Software</b>: Integrated management of requests, assets, and workflow </h6>
                <h6><b>Hardware</b>: Pre-certified Internet of Things “IoT” devices & network infrastructure</h6>
                <h6><b>3rd Party Integration</b>: 3rd party hardware and software integration for centralized decisions</h6>
                <h6><b>All Device Types Supported</b>: Desktop, tablet, and mobile usage is standard</h6>
                <h6></h6>
                <Link to='/contact'><button>Contact Us for Demo</button></Link>
            </div>
             <img src={devices} className='homePageImages' alt = 'endtoend'/>
        </div>
        
        <hr style={{width: '90%', backgroundColor: 'white', height: '2px'}}/>        
        
        <div className='iconsHeader'> What Does ACADA Enable? </div>
        
        <div style = {{display: 'flex', backgroundColor: 'white', justifyContent: 'space-around', alignSelf:  "flex-end", margin: '10px 40px'}}> 
            <div className='homePageCaption' onClick={(e) => {e.preventDefault(); navigate('/cities')}}>
                <div style={{margin: 'auto'}}>Smarter Cities</div>
                <img src={citiesImage} className='homePageCenterImage' alt='cities'/>
            
            </div>
            <div className='homePageCaption' onClick={(e) => {e.preventDefault(); navigate('/campus')}}>
                <div style={{margin: 'auto'}}>Smarter Campuses</div>
                <img src={campusImage} className='homePageCenterImage' alt='campuses'/>
            </div>
            <div className='homePageCaption' onClick={(e) => {e.preventDefault(); navigate('/properties')}}>
                <div style={{margin: 'auto'}}>Smarter Properties</div>
                <img src={farmImage} className='homePageCenterImage' alt='properties'/>
            </div>
        </div>
     
        <hr style={{width: '90%', backgroundColor: 'white', height: '2px'}}/>
       
        <div className='iconsHeader2'> What's All The Buzz? </div>        
        <div className='fullScreen2' style={{backgroundColor: 'white'}}>
            <div>
                <h4><b>"It was super easy to use!"</b></h4>
                <h6>Joe V. Royal Oak, MI on using RAH! for the first time</h6>
            </div>
            <div>
                <h4><b>“We’ve have worked with many large Smart City providers, and ACADA™ is the easiest and most intuitive solution we have seen.”</b></h4>
                <h6> Aimee S. – COO at IoT Device Manufacturer</h6>
            </div>
            <div>
                <h4><b>“You mean we get to try it before we buy it?”</b></h4>
                <h6> Bob C. – DPW Director, Metro Detroit Area local government, on how ACADA&trade; is ready immediately for any local governmenet to try</h6>

            </div>
                <div>
                <h4><b>“Very informative and robust!”</b></h4>
                <h6> Dustin L. – IT Director, metro Detroit area local government, on ACADA&trade;</h6>

            </div>
        </div>

        <hr style={{width: '90%', backgroundColor: 'white', height: '2px'}}/>

        <div className='fullScreen' style={{backgroundColor: 'white'}}>
            <div>
                <h3>How Do We Get Started? </h3>
                <h6>- Learn the number one problem with technology adoption for smaller organizations. </h6>
                <h6>- Uncover the 3 barriers preventing you from gaining control of your workflow. </h6>
                <h6>- Understand how to answer the question “What’s Going on In My City, Campus, or Facility?” </h6>
                <h6>- Re-program how you manage, visualize, communicate. </h6>
                <Link to='/resources'><button>Learn From Our Resources</button></Link>
                    </div>
 
            <img src={getstarted} className='homePageRightImage' alt = 'getstarted'/>
        </div>

             <Footer />
    </>)
}

export default Home;
