import Header from './Header';
import Subheader from './Subheader';
import Footer from "./Footer";
import { Link } from 'react-router-dom';
import Pricingpic from '../assets/images/PricingPic.png';

function Pricing() {
    return (<div style={{minHeight: '100vh'}}>
        <Header links={true} background={'white'} headerGap={true}/>
        <Subheader text={'Pricing'}/>
	<div className='iconsHeader'> ACADA pricing is based on population/size and version </div>
	<div className='fullScreen' style={{backgroundColor: 'white'}}>
		<img src={Pricingpic} className='homePageImages' alt = 'pricing'/>
	</div>
        <Footer />
    </div>)
}




export default Pricing
