import wrench from '../assets/images/Wrench_Trimed.jpg';

function Subheader({text}) {

    return (<div style={{position: 'relative'}}>
        <img src={wrench} alt='wrench' style={{width: '100%'}}/>
        <div className='subHeaderText'>{text}</div>
    </div>)

}

export default Subheader;