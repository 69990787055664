import Header from "./Header";
import Subheader from "./Subheader";
import Footer from "./Footer";
import WaterMeterDataPDF from '../assets/docs/Devices/AIoT-DW-001-Door-Window-technical-Specs.pdf';
import DoorWindowDataPDF from '../assets/docs/Devices/AIoT-DW-001-Door-Window-technical-Specs.pdf';
import RemoteShutOffValveDataPDF from '../assets/docs/Devices/AIoT-RSV-001-ACADA-Remote-Shut-Off-Valve.pdf';
import AcousticLeakDataPDF from '../assets/docs/Devices/AIoT-AL-001-ACADA-Acoustic-Leak-Detector.pdf';
import TrashBin1DataPDF from '../assets/docs/Devices/AIoT-TR-001-ACADA-Trash-Bin-Device.pdf';
import TrashBin2DataPDF from '../assets/docs/Devices/AIoT-TR-002-ACADA-Trash-Bin-Device.pdf';
import AirQualityDataPDF from '../assets/docs/Devices/AIoT-AQ-001-Air-Quality-technical-Specs.pdf';
import RodentTrapRatDataPDF from '../assets/docs/Devices/AIoT-RT-002-ACADA-Rodent-Trap-Rat.pdf';
import RodentTrapMouseDataPDF from '../assets/docs/Devices/AIoT-RT-003-ACADA-Rodent-Trap-Mouse.pdf';
import ParkingSurfaceMountDataPDF from '../assets/docs/Devices/AIoT-PKNG-001-Parking-technical-Specs.pdf';
import ParkingInGroundDataPDF from '../assets/docs/Devices/AIoT-PKNG-002-Parking-technical-Specs.pdf';
import GPSBatteryDataPDF from '../assets/docs/Devices/AIoT-GPS-002-ACADA-GPS-Battery-Device.pdf';
import GPSWiredDataPDF from '../assets/docs/Devices/AIoT-GPS-003-ACADA-GPS-Hard-Wired-Device.pdf';
import LevelSensorDataPDF from '../assets/docs/Devices/AIoT-LVL-001-ACADA-Level-Sensor.pdf';
import SmokeDetectorDataPDF from '../assets/docs/Devices/AIoT-SMK-001-ACADA-Smoke-Detector.pdf';
import StreetLightsDataPDF from '../assets/docs/Devices/AIoT-lite-001-Street-Light-Controller.pdf';

function DeviceDocs() {

    const DeviceTableHeader = ['Model Number', 'Device Type', 'Data Sheet', 'Installation Manual', 'TroubleShooting']

    return (<div style={{minHeight: '100vh'}}>
        <Header links={true} background={'white'} headerGap={true}/>
        <Subheader text={'Device Documentation'}/>
        {/* <div style={{width: '100%', textAlign: 'center', padding: '10px', marginTop: '105px', color: '#4294f3'}}>ACADA Device Documentation</div> */}
        <h3 style={{width: '100%', textAlign: 'center', padding: '10px', color: '#4294f3'}}></h3>
        <table className='docs'>
            <thead>
                <tr>
                    {DeviceTableHeader.map((Header, index) => <th key={index}>{Header}</th>)}
                </tr>
            </thead>
            <tbody>
                {deviceDocuments.map((device, index) =>
                    <tr key={index}>
                        <td>{device.modelNumber}</td>
                        <td>{device.deviceType}</td>
                        <td><a href={device.dataSheetLink} target='_blank' rel="noreferrer"> {device.dataSheetText}</a></td>
                        <td>{device.installationManual}</td>
                        <td>{device.troubleshooting}</td>
                    </tr>
                )}
            </tbody>
        </table>
        <Footer />
    </div>)
}

const deviceDocuments = [
    {
        modelNumber: 'AIoT-WM-001',
        deviceType: 'Water Meter',
        dataSheetText: 'AIoT-WM-001 Spec',
        dataSheetLink: WaterMeterDataPDF,
        installationManual: 'Coming Soon',
        troubleshooting: 'Coming Soon'
    },
    {
        modelNumber: 'AIoT-DW-001',
        deviceType: 'Door & Window',
        dataSheetText: 'AIoT-DW-001 Spec',
        dataSheetLink: DoorWindowDataPDF,
        installationManual: 'Coming Soon',
        troubleshooting: 'Coming Soon'
    },
    {
        modelNumber: 'AIoT-RSV-001',
        deviceType: 'Remote Shut Off Valve',
        dataSheetText: 'AIoT-RSV-001 Spec',
        dataSheetLink: RemoteShutOffValveDataPDF,
        installationManual: 'Coming Soon',
        troubleshooting: 'Coming Soon'
    },
    {
        modelNumber: 'AIoT-AL-001',
        deviceType: 'Acoustic Leak',
        dataSheetText: 'AIoT-AL-001 Spec',
        dataSheetLink: AcousticLeakDataPDF,
        installationManual: 'Coming Soon',
        troubleshooting: 'Coming Soon'
    },
    {
        modelNumber: 'AIoT-TR-001',
        deviceType: 'Trash Bin',
        dataSheetText: 'AIoT-TR-001 Spec',
        dataSheetLink: TrashBin1DataPDF,
        installationManual: 'Coming Soon',
        troubleshooting: 'Coming Soon'
    },
    {
        modelNumber: 'AIoT-TR-002',
        deviceType: 'Trash Bin',
        dataSheetText: 'AIoT-TR-002 Spec',
        dataSheetLink: TrashBin2DataPDF,
        installationManual: 'Coming Soon',
        troubleshooting: 'Coming Soon'
    },
    {
        modelNumber: 'AIoT-AQ-001',
        deviceType: 'Air Quality',
        dataSheetText: 'AIoT-AQ-001 Spec',
        dataSheetLink: AirQualityDataPDF,
        installationManual: 'Coming Soon',
        troubleshooting: 'Coming Soon'
    },
    {
        modelNumber: 'AIoT-RT-002',
        deviceType: 'Rodent Trap (Rat)',
        dataSheetText: 'AIoT-RT-002 Spec',
        dataSheetLink: RodentTrapRatDataPDF,
        installationManual: 'Coming Soon',
        troubleshooting: 'Coming Soon'
    },
    {
        modelNumber: 'AIoT-RT-003',
        deviceType: 'Rodent Trap (Mouse)',
        dataSheetText: 'AIoT-RT-003 Spec',
        dataSheetLink: RodentTrapMouseDataPDF,
        installationManual: 'Coming Soon',
        troubleshooting: 'Coming Soon'
    },
    {
        modelNumber: 'AIoT-PKNG-001',
        deviceType: 'Parking (Surface Mount)',
        dataSheetText: 'AIoT-PKNG-001 Spec',
        dataSheetLink: ParkingSurfaceMountDataPDF,
        installationManual: 'Coming Soon',
        troubleshooting: 'Coming Soon'
    },
    {
        modelNumber: 'AIoT-PKNG-002',
        deviceType: 'Parking (In Ground)',
        dataSheetText: 'AIoT-PKNG-002 Spec',
        dataSheetLink: ParkingInGroundDataPDF,
        installationManual: 'Coming Soon',
        troubleshooting: 'Coming Soon'
    },
    {
        modelNumber: 'AIoT-GPS-002',
        deviceType: 'GPS (Battery)',
        dataSheetText: 'AIoT-GPS-002 Spec',
        dataSheetLink: GPSBatteryDataPDF,
        installationManual: 'Coming Soon',
        troubleshooting: 'Coming Soon'
    },
    {
        modelNumber: 'AIoT-GPS-003',
        deviceType: 'GPS (Hard Wired)',
        dataSheetText: 'AIoT-GPS-003 Spec',
        dataSheetLink: GPSWiredDataPDF,
        installationManual: 'Coming Soon',
        troubleshooting: 'Coming Soon'
    },
    {
        modelNumber: 'AIoT-LVL-001',
        deviceType: 'Level Sensor',
        dataSheetText: 'AIoT-LVL-001 Spec',
        dataSheetLink: LevelSensorDataPDF,
        installationManual: 'Coming Soon',
        troubleshooting: 'Coming Soon'
    },
    {
        modelNumber: 'AIoT-SMK-001',
        deviceType: 'Smoke Detector',
        dataSheetText: 'AIoT-SMK-001 Spec',
        dataSheetLink: SmokeDetectorDataPDF,
        installationManual: 'Coming Soon',
        troubleshooting: 'Coming Soon'
    },
    {
        modelNumber: 'AIoT-Lite-001',
        deviceType: 'Street Lights',
        dataSheetText: 'AIoT-Lite-001 Spec',
        dataSheetLink: StreetLightsDataPDF,
        installationManual: 'Coming Soon',
        troubleshooting: 'Coming Soon'
    }
]

export default DeviceDocs
