import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './utils/ScrollToTop';
import Home from './componets/Home';
import Cities from './componets/Cities';
import Campus from './componets/Campus';
import Properties from './componets/Properties';
import Legal from './componets/Legal';
import DeviceDocs from './componets/DeviceDocs';
import About from './componets/About';
import Contact from './componets/Contact';
import Resources from './componets/Resources';
import FAQ from './componets/FAQ';
import RAH from './componets/RAH';
import Pricing from './componets/Pricing';
import ExplainerVideos from './componets/ExplainerVideos';
import ACADA from './componets/ACADA';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/about' element={<About />} />
          <Route exact path='/cities' element={<Cities />} />
          <Route exact path='/campus' element={<Campus />} />
          <Route exact path='/Properties' element={<Properties />} />
          <Route exact path='/legal' element={<Legal />} />
          <Route exact path='/DeviceDocs' element={<DeviceDocs />} />
          <Route exact path='/contact' element={<Contact />} />
          <Route exact path='/resources' element={<Resources />} />
          <Route exact path='/FAQ' element={<FAQ />} />
          <Route exact path='/rah' element={<RAH />} />
          <Route exact path='/ACADA' element={<ACADA />} />
          <Route exact path='/Pricing' element={<Pricing />} />
          <Route exact path='/ExplainerVideos' element={<ExplainerVideos />} />
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  )
}

export default App;
