import floodIcon from '../assets/icon/flood.jpg';
import leakIcon from '../assets/icon/leak.jpg';
import lightsIcon from '../assets/icon/lights.jpg';
import meterIcon from '../assets/icon/meter.jpg';
import parkingIcon from '../assets/icon/parking.jpg';
import readingIcon from '../assets/icon/reading.jpg';
import restroomIcon from '../assets/icon/restroom.jpg';
import rodentIcon from '../assets/icon/rodent.jpg';
import securityIcon from '../assets/icon/security.jpg';
import shutOffIcon from '../assets/icon/shut-off.jpg';
import smokeIcon from '../assets/icon/smoke.jpg';
import trashIcon from '../assets/icon/trash.jpg';

import '../css/homeServiceIcon.css';

function HomeServiceIcons () {
    return(<div style={{paddingBottom:  "50px"}}>
        <div className='rowStyle'>
            <div className='cellStyle'>
                <div style={leftTextStyle}>Smart Trash Bins and Dumpsters</div>
                <img src={trashIcon} style={iconStyle} alt='Smart Trash Bins and Dumpsters'/>
            </div>
            <div className='cellStyle'>
                <img src={smokeIcon} style={iconStyle} alt='Smart Smoke Detectors / Air Quality'/>
                <div style={rightTextStyle}>Smart Smoke Detectors / Air Quality</div>
            </div>
        </div>
        <div className='rowStyle'>
            <div className='cellStyle'>
                <div style={leftTextStyle}>Connected Street Lights</div>
                <img src={lightsIcon} style={iconStyle} alt='Connected Street Lights'/>
            </div>
            <div className='cellStyle'>
                <img src={securityIcon} style={iconStyle} alt='Door and Window Security Sensors'/>
                <div style={rightTextStyle}>Door and Window Security Sensors</div>
            </div>
        </div>
        <div className='rowStyle'>
            <div className='cellStyle'>
                <div style={leftTextStyle}>Smart Parking and EV Station Availability</div>
                <img src={parkingIcon} style={iconStyle} alt='Smart Parking and EV Station Availability'/>
            </div>
            <div className='cellStyle'>
                <img src={readingIcon} style={iconStyle} alt='LoRA Gateway'/>
                <div style={rightTextStyle}>LoRA Gateway</div>
            </div>
        </div>
        <div className='rowStyle'>
            <div className='cellStyle'>
                <div style={leftTextStyle}>Acoustic Water Line Leak Detection</div>
                <img src={leakIcon} style={iconStyle} alt='Acoustic Water Line Leak Detection'/>
            </div>
            <div className='cellStyle'>
                <img src={shutOffIcon} style={iconStyle} alt='Remote Meter Shut-off Valve'/>
                <div style={rightTextStyle}>Remote Meter Shut-off Valve</div>
            </div>
        </div>
        <div className='rowStyle'>
            <div className='cellStyle'>
                <div style={leftTextStyle}>Flood Monitoring</div>
                <img src={floodIcon} style={iconStyle} alt='Flood Monitoring'/>
            </div>
            <div className='cellStyle'>
                <img src={restroomIcon} style={iconStyle} alt='Restroom Product Monitoring'/>
                <div style={rightTextStyle}>Restroom Product Monitoring</div>
            </div>
        </div>
        <div className='rowStyle'>
            <div className='cellStyle'>
                <div style={leftTextStyle}>Water Meters</div>
                <img src={meterIcon} style={iconStyle} alt='Water Meters'/>
            </div>
            <div className='cellStyle'>
                <img src={rodentIcon} style={iconStyle} alt='Smart Rodent Control'/>
                <div style={rightTextStyle}>Smart Rodent Control</div>
            </div>
        </div>
    </div>)
}

const leftTextStyle = {
    width: '250px', 
    fontSize: '20px', 
    textAlign: 'right', 
    paddingRight: '25px'
}

const rightTextStyle = {
    width: '250px', 
    fontSize: '20px', 
    textAlign: 'left', 
    paddingLeft: '25px'
}

const iconStyle = {
    width: '90px', 
    flex: 'none'
}

export default HomeServiceIcons
