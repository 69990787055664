import '../css/homeBulletPoints.css';

function HomeBulletPoints({content}) {

    return (
        <>
            <div className='header'>
                {content.header}
            </div>
            <div className='subHeader'>
                {content.subHeader}
            </div>
            <div>
                {content.bullets.map((bullet, index) =>
                    <div style={{display: 'flex', alignItems: 'flex-start'}} key={index}>
                        <span className='bullet' /> 
                        <div className='bulletText'>{bullet}</div>
                    </div>
                )}
            </div>
        </>
    )
}

export default HomeBulletPoints;