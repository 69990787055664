import Header from './Header';
import Subheader from './Subheader';
import Footer from "./Footer";
import { Link } from 'react-router-dom';


function ExplainerVideos() {
    return (<div style={{minHeight: '100vh'}}>
        <Header links={true} background={'white'} headerGap={true}/>
        <Subheader text={'Explainer Videos'}/>

        <div className='fullScreen' style={{backgroundColor: 'white', margin: '60px 60px'}}>
        {/*<div style = {{display: 'flex', backgroundColor: 'white', justifyContent: 'space-around', alignSelf:  "flex-end", margin: '60px 60px'}}>*/} 
  
            <iframe height="280" width="500" src="https://www.youtube.com/embed/i_uoGm6EwJI">
            </iframe>
            
            <iframe height="280" width="500" src="https://www.youtube.com/embed/kRd89X98ylY">
            </iframe>

            <iframe height="280" width="500" src="https://www.youtube.com/embed/ud20Y9p_Wy4">
            </iframe>
        </div>

        <div className='fullScreen' style={{backgroundColor: 'white'}}>
            <iframe height="280" width="500"   
            src="https://www.youtube.com/embed/oCBtklNgB6o">
            </iframe>

            <iframe height="280" width="500"   
            src="https://www.youtube.com/embed/Pp1_o6_XbYQ">
            </iframe>
        </div>

        <Footer />
    </div>)
}

export default ExplainerVideos
