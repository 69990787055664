import Header from './Header';
import Subheader from './Subheader';
import Footer from "./Footer";
import EULApdf from '../assets/docs/Legal/EULA-05_12_2021.pdf';
import Privacypdf from '../assets/docs/Legal/Privacy.pdf';
import TermsPDF from '../assets/docs/Legal/Terms.pdf';

function Legal() {
    return (<div style={{minHeight: '100vh'}}>
        <Header links={true} background={'white'} headerGap={true}/>
        <Subheader text={'Terms of Service'}/>
        <div className='legalButtonWrapper'>
            {legalDocuments.map((document, index) =>
                <a href={document.documentLink} className='legalButton' key={index} target='_blank' rel="noreferrer">
                    <div>{document.documentName}</div>
                    {document.documentNameLine2 && <div>{document.documentNameLine2}</div> }
                </a>
            )}
        </div>
        <Footer />
    </div>)
}

const legalDocuments = [
    {
        documentName:  'End User',
        documentNameLine2: 'License Agrement',
        documentLink: EULApdf
    },
    {
        documentName:  'Privacy Policy',
        documentLink: Privacypdf
    },
    {
        documentName:  'Terms & Conditions',
        documentLink: TermsPDF
    }
]


export default Legal
