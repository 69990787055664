import { useEffect, useState } from 'react';
import HomeBulletPoints from './HomeBulletPoints';
import HomeServiceIcons from './HomeServiceIcons';
import Header from './Header';
import Footer from './Footer';
import text from "../assets/text/homepage";
import problems from '../assets/images/problems1.png';
import whatsgoingon from '../assets/images/WGOIMC.png';
import endtoend from '../assets/images/endtoend.png';
import { Link } from 'react-router-dom';

function Cities() {
    const [topTextPosition, setTopTextPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const firstTextDiv = document.getElementById('topTextPosition');
            setTopTextPosition(firstTextDiv.getBoundingClientRect().top);
        }
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);
        handleScroll();
        return () => {window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleScroll)
        }
    });

    return (<>
        <Header onScroll={true} topTextPosition={topTextPosition} />
        <div className="hero hero-cities">
            <div className="hero-overlay" >
                <h3 id='topTextPosition'>{text.firstPanel.header}</h3>
                <h6>{text.firstPanel.paragraph}</h6>
                <h6 style={{fontStyle: 'Bold'}}>{text.firstPanel.statement}</h6>
                <Link to='/contact'><button>Request A Demo</button></Link>
            </div>
        </div>
        <div className='fullScreen' style={{backgroundColor: 'white'}}>
            <div>
                <h3>{text.devicesPanel.header}</h3>
                <h6>{text.devicesPanel.paragraph}</h6>
                <Link to='/contact'><button>Request Information</button></Link>
            </div>
            <img src={problems} className='homePageImages' alt = 'problems'/>
        </div>
        <div className='fullScreen' id='reverseOrder' style={{backgroundColor: '#E2E2E2'}}>
            <div className='homeBulletPointsWrapper'>
                <HomeBulletPoints content={text.firstBulletPoints} />
                <Link to='/RAH'><button>Learn About RAH!</button></Link> 
            </div>
{/* */}                
                             
                <img src={whatsgoingon} className='homePageImages' alt = 'Whats Going On In My City?' /> 
        </div>
        <div className='fullScreen'>
            <div className='homeBulletPointsWrapper'>
                <HomeBulletPoints content={text.secondBulletPoints}  />
            </div>
            <img src={endtoend} className='homePageImages' alt = 'End to End'/>
        </div>
        <hr style={{width: '90%', backgroundColor: '#E2E2E2', height: '2px'}}/>
        <div className='iconsHeader'> Practical, Everyday Workflow Devices available on ACADA&trade; today. </div>
        <HomeServiceIcons />
        <Footer />
    </>);
}

export default Cities
