import { useEffect, useState } from 'react';
import HomeBulletPoints from './HomeBulletPoints';
import HomeServiceIcons from './HomeServiceIcons';
import Header from './Header';
import Footer from './Footer';
import text from "../assets/text/homepage";
import RAHsteps from '../assets/images/RAH4step.png';
import streetLightsService from '../assets/images/WGOIMC.png';
import checkDone from '../assets/images/endtoend.png';
import rahlady from '../assets/images/rahlady.png';
import { Link } from 'react-router-dom';

function RAH() {
    const [topTextPosition, setTopTextPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const firstTextDiv = document.getElementById('topTextPosition');
            setTopTextPosition(firstTextDiv.getBoundingClientRect().top);
        }
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);

        handleScroll();
        return () => {window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleScroll)
        }
    });

    const redirectToRah = () => {
        window.open('https://www.rah.city/', '_blank')
    }
    
    const redirectToRahSign = () => {
    window.open('https://www.rah.city/signup/', '_blank')
    }

    return (<>
        <Header onScroll={true} topTextPosition={topTextPosition} />
        <div className="hero hero-rah">
            <div className="hero-overlay" >
                <h3 id='topTextPosition'>{text.firstPanelRAH.header}</h3>
                <h6>{text.firstPanelRAH.paragraph}</h6>
                <h6 style={{fontStyle: 'Bold'}}>{text.firstPanelRAH.statement}</h6>
                {/* directly <button onClick={() => {window.open('https://rah.logimic.online/', '_blank')}}>Try RAH! Now</button> */}
                <button onClick={redirectToRah}>Try RAH! Now</button> {/* should be preferred in react as far as I know */}
            </div>

        </div>

        <div className='fullScreen' style={{backgroundColor: 'white'}}>
            <div>
                <h3>{text.devicesRAH.header}</h3>
                <h6>{text.devicesRAH.paragraph}</h6>
                 <button onClick={redirectToRahSign}>Sign Up Your City Now</button> 
            </div>
            <img src={RAHsteps} className='homePageImages' alt = 'RAH4steps'/>
        </div>

        <div className='fullScreen' id='reverseOrder' style={{backgroundColor: '#E2E2E2'}}>
            <div className='homeBulletPointsWrapper'>
                <HomeBulletPoints content={text.firstBulletRAH} />
            </div>
            <img src={rahlady} className='homePageImages' alt = 'RAH Lady'/>
        </div>

        <div className='fullScreen'>
            <div className='homeBulletPointsWrapper'>
                <HomeBulletPoints content={text.secondBulletRAH}  />
            </div>
            <img src={checkDone} className='homePageImages' alt = 'check done'/>
        </div>
        <div className='fullScreen'>
           <iframe height="800" width="600" src="https://www.rah.city" allow="geolocation 'self' https://www.rah.city">
           </iframe>
        </div>
            
        <Footer />
    </>);
}

export default RAH
