import Header from './Header';
import wrench from '../assets/images/Wrench2.JPG';
import Footer from './Footer';
import northcuttHeadshot from '../assets/images/Northcutt.JPG';
import gordonHeadshot from '../assets/images/Gordon.JPG';
import valnyHeadshot from '../assets/images/Valny.JPG';

const parser = new DOMParser();
const visionTextRaw = "Our Vision is to transform the technology adoption paradigm to help underserved markets thrive. Our Mission is to empower our customers to work smarter, faster, and safer. To do this, we will build best-in-class ecosystems and productivity services, starting with local government, then expanding into similar close adjacencies."

const missionTextRaw = "Our Vision is to transform the technology adoption paradigm to help underserved markets thrive. Our Mission is to empower our customers to work smarter, faster, and safer. To do this, we will build best-in-class ecosystems and productivity services, starting with local government, then expanding into similar close adjacencies. We've built a team of municipal, product, software, and device experts to become the global answer for more efficient and safer small- and medium-sized city workflow. Here is our Leadership Team:"


const visionDecodedString = parser.parseFromString(`<!doctype html><body>${visionTextRaw}`, 'text/html').body.textContent;
const missionDecodedString = parser.parseFromString(`<!doctype html><body>${missionTextRaw}`, 'text/html').body.textContent;

const team = [
    {name: 'Brett Northcutt', title: 'Chief Executive Officer', headshot: northcuttHeadshot, creditials: []},
    {name: 'Michael Gordon', title: 'Chief Commercialization Officer', headshot: gordonHeadshot, creditials: []},
    {name: 'Michal Valny', title: 'Technology Director', headshot: valnyHeadshot, creditials: []},
]

function About () {

    return <div className='about'>
        <Header headerGap={true}/>
        <div className='aboutSubheader'>
            <img src={wrench} alt='wrench' style={{width: '100%'}}/>
        </div>
        <div className='aboutContentWrapper'>
            <div className='aboutTextLine'>{missionDecodedString}</div>
            <div className='aboutTeamWrapper'>
                {team.map((member, i) =>
                <div className='aboutTeamMember' key={i}>
                    <img src={member.headshot} alt='headshot' style={{height: '180px'}}/>
                    <div>{member.name}</div>
                    <div style={{fontWeight: 300}}>{member.title}</div>
                </div>)}
            </div>
        </div>
        <Footer />
    </div>
}

export default About
