import Header from './Header';
import Subheader from './Subheader';
import Footer from "./Footer";
import { Link } from 'react-router-dom';


function Resources() {
    return (<div style={{minHeight: '100vh'}}>
        <Header links={true} background={'white'} headerGap={true}/>
        <Subheader text={'Helpful Resources'}/>

        <div className='fullScreen' style={{backgroundColor: 'white'}}>
            <div>
                <h3>"Smart City" ... What is it? </h3>
                <h6><a href="https://www.smartcitiesdive.com/news/back-to-basics-what-is-a-smart-city/609225/" target="_blank">Back to basics - what is a smart city?</a> 
                </h6>
                 <h6><a href="https://www-technologyreview-com.cdn.ampproject.org/c/s/www.technologyreview.com/2022/06/29/1054005/toronto-kill-the-smart-city/amp/" target="_blank">Toronto wants to kill the smart city*</a>
                </h6>    
                <h6><a href="https://www.technologyreview.com/2022/06/24/1053969/smart-city-unrealized-utopia" target="_blank">The smart City is a perpetually unrealized utopia*</a>  
                 </h6>
            
                
                <h3>Smarter City Pilots and Use Cases</h3>
                <h6><a href="https://nextcity.org/urbanist-news/pittsburgh-modernizes-waste-collection-while-combating-climate-change" target="_blank">Pittsburgh Modernizes Waste Collection While Combating Climate Change</a>
                </h6>
                <h6><a href="https://www.waterworld.com/water-utility-management/article/14280668/transforming-a-small-town-into-a-major-smart-city" target="_blank">Transforming a Small Town Into a Major Smart City</a>
                </h6>
                <h6><a href="https://www.smartcitiesdive.com/news/somerville-pilots-smart-boxes-rat-control/619946/?utm_source=Sailthru&utm_medium=email&utm_campaign=Issue:%202022-03-08%20Smart%20Cities%20Dive%20Newsletter%20%5Bissue:40233%5D&utm_term=Smart%20Cities%20Dive" target="_blank">The latest front for smart city tech? Rat control</a>
                 </h6>
                 <h6><a href="https://www.smartcitiesdive.com/news/smart-streetscapes-engineering-research-center-west-palm-beach-new-brunswick-harlem/629684/" target="_blank">Smart streetscapes center plans community-driven research in 3 cities</a>
                </h6>
                <h6> <a href="https://www.iotforall.com/how-various-industries-approach-smart-waste-management%ef%bf%bc?utm_campaign=coschedule&utm_source=linkedin_company&utm_medium=IoT%20For%20All&utm_content=How%20Various%20Industries%20Approach%20Smart%20Waste%20Management%EF%BF%BC" target="_blank">How Various Industries Approach Smart Waste Management</a>
                </h6>     
                <h6> <a href="https://smartcitiesconnect.org/colorado-launches-new-smart-cities-incubator/" target="_blank">Colorado Lauches New Smart Cities Incubator*</a>
                </h6>  
              <h6> <a href="https://aws.amazon.com/blogs/industries/smart-metering-for-water-utilities/" target="_blank">What is AMI or "Smart Water"?*</a>
                </h6>  
                <h6> <a href="https://www.cityofwarren.org/departments/water-and-sewer-system/automatic-water-meter-replacement-program/" target="_blank">The City of Warren Michigan Saves up to $900,000 per year by converting to Smart Water Meters*</a>
                </h6> 
                <h6> <a href="https://www.townofcary.org/projects-initiatives/smart-and-connected-communities-program" target="_blank">The City of Cary, NC Smart Cities Pilot*</a>
                </h6>
                <h6> <a href="https://www.itron.com/na/blog/itron-news/a-blooming-smart-city" target="_blank">Alcorcón, Spain: A Blooming Smart City*</a>
                </h6>          
                
                <h3>Technology Adoption Challenges</h3>
                <h6><a href="https://www.smartcitiesdive.com/news/how-to-foster-a-more-innovative-culture-at-city-hall-report/621468/?:%202022-04-04%20Smart%20Cities%20Dive%20Newsletter%20%5Bissue:40842%5D" target="_blank">How to Foster a more innovative culture at city hall*</a>                
                </h6>
                <h6><a href="https://www-govtech-com.cdn.ampproject.org/c/s/www.govtech.com/opinion/what-is-innovation-debt?_amp=true" target="_blank">What is innovation debt?"</a>
                </h6>

                <h3>Data Driven Approaches to Improving Local Government</h3>
                <h6><a href="https://gcn.com/cloud-infrastructure/2022/05/it-really-technology-makes-city-smart/367358/" target="_blank">Is it really technology that makes a city smart?</a>
                </h6>
                <h6><a href="https://www-civicpulse-org.cdn.ampproject.org/c/s/www.civicpulse.org/amp/opportunities-for-improved-peer-benchmarking-in-local-governance" target="_blank">Opportunities for improved peer benchmarking in local government*"</a>
                </h6>
                <h6><a href="https://aws.amazon.com/blogs/publicsector/how-data-driven-technology-helps-law-enforcement-agencies-improve-constituent-outcomes/" target="_blank">How Data Driven Technology Helps Law Enforcement Agencies Improve Constituent Outcomes*"</a>
                </h6>
                          
                <h3>Labor Shortages in Local Government </h3>
                <h6><a href="https://www.pbs.org/newshour/amp/show/american-cities-states-cant-find-enough-workers-despite-an-influx-of-federal-funding" target="_blank">American Cities Cant Find Enough Workers Despite Funding</a>                
                </h6>
             
                <h3>Help with Infrastructure Funding</h3>
                <h6><a href="https://www.smartcitiesdive.com/news/local-infrastructure-hub-nlc-small-cities-infrastructure-grants-technical-assistance-bootcamps/629997/" target="_blank">‘Boot camps’ open to help small cities compete for infrastructure grants</a>                   </h6>
                
                <h3>Impact of Extreme Weather On Local Government </h3>
                <h6><a href="https://www.npr.org/2022/08/31/1118786411/city-parks-global-warming-heat-wave" target="_blank">City parks global warming heat wave</a>
                </h6>                               
                <h6><a href="https://apple.news/AT6LP88kSSs-A8DMxB1wBCQ" target="_blank">Jackson, Mississippi, Water Crisis Previews a Wetter, Hotter US Future*</a>
                </h6>
                
                <h3>Rapidly Advancing Technology</h3>
                <h6><a href="https://www.clickondetroit.com/news/local/2022/03/03/how-a-new-parking-system-in-part-of-downtown-royal-oak-is-causing-frustration-for-drivers/" target="_blank">How a new paring system in part of downtown Royal Oak is causing frustration for drivers</a>
                </h6>
                 <h6><a href="https://techcrunch.com/2022/08/30/populus-doubles-down-on-curb-management-platform-with-series-a-raise/" target="_blank">Populus doubles down on curb management platform*</a>
                </h6>
                 <h6><a href="https://www-forbes-com.cdn.ampproject.org/c/s/www.forbes.com/sites/forbestechcouncil/2022/07/27/smart-city-technologies-that-could-soon-change-the-way-we-live-and-work/amp//" target="_blank">Smart City technologies which could soon change the way we live and work*</a>
                </h6>                
                <h6><a href="https://www.freep.com/story/news/local/michigan/oakland/2022/01/19/royal-oak-parking-meter-glitches/6584244001/" target="_blank">Royal Oak parking meter glitches</a>
                </h6>
                <h6><a href="https://www.deadlinedetroit.com/articles/30654/new_downtown_royal_oak_parking_system_pushes_people_away_business_owners_say" target="_blank">New downtown Royal Oak parking system pushed people away business owners say</a>
               </h6>
                
            </div> 
        </div>               
                
        <Footer />
    </div>)
}

export default Resources
