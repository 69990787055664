import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import HomeBulletPoints from './HomeBulletPoints';
import text from "../assets/text/homepage";
import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';

//Images
import citiesImage from '../assets/images/CellPhoneTower.jpg';
import campusImage from '../assets/images/UniversityText.jpg';
import forrent from '../assets/images/forrent.jpg';
import endtoend from '../assets/images/endtoend.png';
import monitor from '../assets/images/Monitor.png';
import heatmap from '../assets/images/Heatmap.png';
import temppic from '../assets/images/dashboard_mockup.png';
import acadahand from '../assets/images/ACADAhand.png';
import servicerequest from '../assets/images/servicerequest.png';
import ACADAreports from '../assets/images/ACADAreports.png';


function ACADA () {
    const navigate = useNavigate();
    const [topTextPosition, setTopTextPosition] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const firstTextDiv = document.getElementById('topTextPosition');
            setTopTextPosition(firstTextDiv.getBoundingClientRect().top);
        }
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);
        handleScroll();
        return () => {window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleScroll)
        }
    });

    
        return(<>
        <Header onScroll={true} topTextPosition={topTextPosition} />
        <div className="hero hero-ACADA">
            <div className="hero-overlay" id='topTextPosition'>
                <h3> ACADA&trade;: Where Smart Meets Simple</h3>
                <h6> With many organizations already facing numerous complex day-to-day issues, adopting new technology is confusing, complicated, and costly. </h6>
                <h6> We solve these problems, and more, with ACADA&trade;: Where Smart Meets Simple.</h6>
                <Link to='/cities'><button>ACADA&trade; for Local Government</button></Link>
            </div>
        </div>
        
        <div className='fullScreen' style={{backgroundColor: 'white'}}>
            <div>
                <h3> ACADA&trade;:Your Answer for Easy, Affordable, and Convenient Technology Adoption.</h3>
                <h6><b>Software</b>: Integrated management of requests, assets, and workflow </h6>
                <h6><b>Hardware</b>: Pre-certified Internet of Things “IoT” devices & network infrastructure</h6>
                <h6><b>3rd Party Integration</b>: Where possible, we integrate with 3rd party hardware and software providers</h6>
                <h6><b>All Device Types Supported</b>: Desktop, tablet, or mobile versions are standard</h6>
                <h6></h6>
                <Link to='/contact'><button>Contact Us for Demo</button></Link>
            </div>
             <img src={endtoend} className='homePageImages' alt = 'endtoend'/>
        </div>
        
        <div className='fullScreen' id='reverseOrder' style={{backgroundColor: '#E2E2E2'}}>
            <div className='homeBulletPointsWrapper'>
                <HomeBulletPoints content={text.firstBulletACADA} />
                <Link to='/ExplainerVideos'><button>Watch ACADA&trade; in Action</button></Link> 
            </div>

                <img src={acadahand} className='homePageImages' alt = 'Mobile' /> 
        </div>

        <div className='fullScreen' style={{backgroundColor: 'white'}}>
            <div className='homeBulletPointsWrapper'>
                <HomeBulletPoints content={text.secondBulletACADA} />
                <Link to='/Contact'><button>Ask for a Demo</button></Link> 
            </div>

                <img src={monitor} className='homePageImages' alt = 'Dashboard' /> 
        </div>

        <div className='fullScreen' id='reverseOrder'style={{backgroundColor: '#E2E2E2'}}>
            <div className='homeBulletPointsWrapper'>
                <HomeBulletPoints content={text.thirdBulletACADA} />
                <Link to='/Contact'><button>Ask for a Demo</button></Link> 
            </div>
                <img src={servicerequest} className='homePageImages' alt = 'Service Requests' /> 
        </div>
        
        <div className='fullScreen' style={{backgroundColor: 'white'}}>
            <div className='homeBulletPointsWrapper'>
                <HomeBulletPoints content={text.fourthBulletACADA} />
                <Link to='/Contact'><button>Ask for a Demo</button></Link> 
            </div>
                <img src={heatmap} className='homePageImages' alt = 'Heatmap' /> 
        </div>

        <div className='fullScreen' id='reverseOrder'style={{backgroundColor: '#E2E2E2'}}>
            <div className='homeBulletPointsWrapper'>
                <HomeBulletPoints content={text.fifthBulletACADA} />
                <Link to='/Contact'><button>Ask for a Demo</button></Link> 
            </div>
                <img src={ACADAreports} className='homePageImages' alt = 'Reports' /> 
        </div>
        <div className='iconsHeader'> Who Uses ACADA&trade;? </div>

        <div style = {{display: 'flex', backgroundColor: 'white', justifyContent: 'space-around', alignSelf:  "flex-end", margin: '10px 40px'}}> 
            <div className='homePageCaption' onClick={(e) => {e.preventDefault(); navigate('/cities')}}>
                <div style={{margin: 'auto'}}>Smarter Cities</div>
                <img src={citiesImage} className='homePageCenterImage' alt='cities'/>
            </div>
            <div className='homePageCaption' onClick={(e) => {e.preventDefault(); navigate('/campus')}}>
                <div style={{margin: 'auto'}}>Smarter Campuses</div>
                <img src={campusImage} className='homePageCenterImage' alt='campuses'/>
            </div>
            <div className='homePageCaption' onClick={(e) => {e.preventDefault(); navigate('/properties')}}>
                <div style={{margin: 'auto'}}>Smarter Properties</div>
                <img src={forrent} className='homePageCenterImage' alt='properties'/>
            </div>
        </div>
        
        <div className='fullScreen' style={{backgroundColor: 'white'}}>
            <div>
                <h6></h6>
            </div>
        </div>
       
        <Footer />
    </>)
}

export default ACADA;
