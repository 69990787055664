import Header from './Header';
import Subheader from './Subheader';
import Footer from "./Footer";
import { Link } from 'react-router-dom';


function FAQ() {
    return (<div style={{minHeight: '100vh'}}>
        <Header links={true} background={'white'} headerGap={true}/>
        <Subheader text={'FAQs'}/>

        <div className='fullScreen' style={{backgroundColor: 'white'}}>
            <div>
                <h3>What does it take to get started?</h3>
                <h6>Sign up <a href="https://www.rah.city/signup" target="_blank">here</a> to instantly launch your instance of ACADA&trade;. You will get an email with your login information and you can watch our <Link to='/ExplainerVideos'target="_blank" >explainer videos</Link> to get started quickly.</h6>
                <h3>Do you integrate with our existing software?</h3>
                <h6>We do our best to work with your existing technology providers. Some are easier to work with. Some dont want to make things easy. If they have an API, we can integrate quickly and easily.</h6>
                <h3>What does onboarding look like?</h3>
                <h6>ACADA&trade; onboarding was designed to be quick and easy. Adding users takes about 1 minute per user. Customizing your categories takes up to 30 minutes. And learning how to use the dashboard, create new services requests, process serveice requests, and run reports are all as easy as learning how ot use email. Our <Link to='/ExplainerVideos'target="_blank" >explainer videos</Link> walk you through all of this in under 15 minutes. </h6>
                <h3>What are some examples of customer solutions?</h3>
                <h6> Here is a live ACADA&trade; Air Quality Dashboard from one of our customers in Europe. It is connected to their air quality monitoring station. Click on any of the icons to see more detail about each parameter.</h6>
                <iframe height="800" width="1200" src="https://public.logimic.online/asd15sTRf4sdf51SdBF5sd1f58a?connName=iTemp2">
                 </iframe>
                 </div> 
        </div>               
                
        <Footer />
    </div>)
}

export default FAQ
