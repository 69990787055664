import { Link } from 'react-router-dom';
import linkedinLogo from '../assets/images/linkedinLogo.png'
import rah_icon from '../assets/images/rah_icon.png'
import acada_icon from '../assets/images/ACADA_logo_transparent.png'

function Footer() {

    return (<>
        <div className='clear'></div>
        <div className='footer'>
            <div>
            <Link to='/legal'>Legal</Link>
            </div>
            <div>
            <Link to='/about'>About</Link>
            </div>
            <div>
            <Link to='/Contact'>Contact Us</Link>
            </div>
            <div>Copyright Allostasis IoT Inc. 2022 - All Rights Reserved.</div>

            <a href="https://smartcity.acada.us" target="_blank" rel="noreferrer"><img src={acada_icon} alt="ACADA" style={{height: '40px', padding: '5px'}}/></a>
            <a href="https://www.rah.city/" target="_blank" rel="noreferrer"><img src={rah_icon} alt="RAH" style={{height: '45px', padding: '2.5px'}}/></a>
            <a href="https://www.linkedin.com/company/allostasis-iot-inc/" target="_blank" rel="noreferrer"><img src={linkedinLogo} alt="LinkedIn" style={{height: '45px', padding: '2.5px'}}/></a>

        
        </div>
    </>)
}

export default Footer
