import Header from "./Header";
import Subheader from "./Subheader";
import Footer from './Footer';
import '../css/contactPage.css';
import { useState } from "react";

function Contact() {

    const [contactInformation, setContactInformation] = useState({
        name: '',
        email: '',
        message: ''
    })

    const encode = (data) => {
        return Object.keys(data)
            .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
            .join("&");
      }

    const onInputChange = e => {
        const { name, value } = e.target;
        setContactInformation({...contactInformation, [name]: value })
    }

    const submitEmail = (e) =>{
        fetch("/", {
            method: "POST",
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
            body: encode({ "form-name": "contact", ...contactInformation })
        })
        .then(() => {
            alert("Thank You!  We will be in contact soon!");
            setContactInformation({name: '', email: '', message: ''});
        })
        .catch(error => alert(error));
        e.preventDefault();
    }

    return(<div style={{minHeight: '100vh'}}>
        <Header links={true} background={'white'} headerGap={true}/>
        <Subheader text={'Contact Us'}/>
        <div style={{width: '100%'}}>
            {/* <!-- A little help for the Netlify post-processing bots --> */}
            <form name="contact" netlify netlify-honeypot="bot-field" action='/' className="hidden">
                <input type="text" name="name" />
                <input type="email" name="email" />
                <textarea name="message"></textarea>
            </form>
            <form onSubmit={(e) => submitEmail(e)}>
                <input type="hidden" name="form-name" value="contact"/>
                <div><label> Name:
                    <input type='text' name="name" placeholder="Please enter your name" onChange={onInputChange} value={contactInformation.name}/> 
                </label></div>
                <div><label> E-mail Address:
                    <input type='text' name="email" placeholder="E-mall address you prefer to be contacted at" onChange={onInputChange} value={contactInformation.email}/>
                </label></div>
                <div><label> Subject:
                    <textarea name="Subject" placeholder="Request demo, Ask about pricing, How do we get started?, Other " onChange={onInputChange} value={contactInformation.subject}/>
                </label></div>
                <div><label> Your Message:
                    <textarea name="message" placeholder="Please share your message with us" onChange={onInputChange} value={contactInformation.message} rows='6'/>
                </label></div>
                <input type="submit" value="Submit" />
            </form>
        </div>
        <Footer />
    </div>)
}

export default Contact
