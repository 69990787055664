import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import allostasisLogo from '../assets/images/AIoTBlueLogo.png'

function Header ({onScroll, topTextPosition, headerGap }) {

    const[headerBackGroundOn, setHeaderBackGroundOn] = useState(false);
    const[headerHeight, setHeaderHeight] = useState(0);

    useEffect(() => {
        if(onScroll) {
            if(headerHeight > topTextPosition) {
                setHeaderBackGroundOn(true)
            } else {
                setHeaderBackGroundOn(false)
            }
        } else {
            setHeaderBackGroundOn(true)
        }
        const handleResize = () => {
            const firstTextDiv = document.getElementById('header');
            setHeaderHeight(firstTextDiv.getBoundingClientRect().height);
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => {window.removeEventListener('resize', handleResize)}
    }, [topTextPosition]);

    return <>
        <header className={headerBackGroundOn ? 'scrollHeader white': 'scrollHeader'} id='header'>
            <div style={{width: '50%', overflowY: 'hidden'}}>
                <Link to= '/'><img src={allostasisLogo} alt = 'aiot logo'  /></Link>
            </div>
            <div>
                <Link to='/ExplainerVideos'>Explainer Videos</Link>
                <Link to='/resources'>Resources</Link>
                <Link to='/faq'>FAQs</Link>
                
            </div>
        </header>
        {headerGap && <div className='headerGap'/>}
    </>
}

export default Header;
