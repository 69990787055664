const text = {
    firstPanel: {
        header:  "Becoming a safer, smarter, more efficient city doesn't need to be complicated or expensive",
        paragraph:  "With small and medium sized local governments already facing many complex issues, technology adoption is fragmented, complicated, and costly.",
        statement:  "We solve these problems, and more, with ACADA."
    },
    
    firstPanelcampus: {
        header:  "Becoming a safer, smarter, more efficient campus doesn't need to be complicated or expensive",
        paragraph:  "With Campus staff facing many complex day-to-day issues, technology adoption is too fragmented, complicated, and costly.",
        statement:  "We solve these problems, and more, with ACADA."
    },
    
    firstPanelpro: {
        header:  "Becoming a safer, smarter, more efficient property manager doesn't need to be complicated or expensive",
        paragraph:  "Property Managers of multi-tenant properties and larger portfolios receive constant requests for maintenance.",
        statement:  "We help manage this problem, and more, with ACADA."
    },
    
    firstPanelRAH: {
        header:  "Citizen Requests Shouldn't Be Difficult To Submit",
        paragraph:  "In many cities, your citizens either need to know who to call, are required to find and fill out a lengthy webform, or need to know what app to download in order to get their concern addressed.",
        statement:  "We help manage these problems with RAH!."
    },
    
    devicesPanel: {
        header:  "Solving Local Government's Biggest Problems, Now",
        paragraph:  "ACADA platform was designed to add value on Day 1, by helping local government more efficiently manage their largest problems, immediately."                
    },
    
    devicesold: {
        header:  "Where Smart Meets Simple",
        paragraph:  "ACADA is an integrated package of Devices, Network, Citizen Request Management, and Asset-Management software focused on the small local government market. Our ACADA platform was designed as a turn-key end-to-end system which simplifies and streamlines the diverse activities of more than 19,000 municipalities, 3000+ county public works agencies, and 35,000 water utilities nationwide."                
    },
    
    devicesRAH: {
        header:  "RAH! - Your Requests, Alerts, and Hazards Solution",
        paragraph:  "A simple 4-step process to submit requests, alerts, hazards, and concerns from any location. Desktop or Mobile. "                
    },
    
    devicesCamp: {
        header:  "Where Smart Meets Simple",
        paragraph:  "ACADA is an integrated package of Devices, Network, Student Request, and Asset-Management software focused on the educational campus market. Our ACADA platform was designed as a turn-key end-to-end system which simplifies and streamlines the diverse activities of educational property and maintenance managers nationwide."                
    },
    
    devicespro: {
        header:  "Where Smart Meets Simple",
        paragraph:  "ACADA is an integrated package of Devices, Network, Tenant Request, and Asset-Maintenance software focused on the property management market. Our ACADA platform was designed as a turn-key end-to-end system which simplifies and streamlines the diverse activities of property maintenance managers nationwide."                
    },

    firstBulletPoints: {
        header:  'Where Smart Meets Simple',
        subHeader:  'ACADA, the only integrated (Citizen Request Management) + (Asset Management) + (Everyday IoT Devices) + (Everyday Worflow) solution, was designed to improve workflow related to these everyday efficiency challenges, and was built on a few key differentiating features:',
        bullets: [
                'Easy to Use: Intuitive software designed to function like you work',    
                'Engages the Community & Gathers Data: RAH!, our Citizen Request tool, easily gathers data from your customers and community. This data is used for future IoT adoption',
                'Efficient Centralized Communication & Prioritization: ACADA Integrates CRM, asset manintenance, and IoT device management to speed up communicaiton and improve decisions',
                'What is going on in my city?: Visibility and accountability in a single-pane-of-glass',
                'Single Vendor Simplicity: Our Integrated CRM, asset manintenance, and IoT device management makes adoption & procurement easy'
        ]
    },
    
    firstBulletRAH: {
        header:  'Easy to Use. Easy to Integrate. Easy to Procure.',
        subHeader:  'RAH! is the only citizen request solution integrated with CRM, asset management, and everyday IoT Devices in ACADA. RAH! was built on a few key unique features:',
        bullets: [
                'Easy to Use: Anybody with access to a web browser can submit in 4 simple steps. No app or account needed.',    
                'Engages the Community: With no app to download or account to create, citizens are more likely to submit their requests, alerts, or Hazards.',
                'Efficient Centralized Communication & Prioritization: RAH!s are integrated into ACADA, along with asset manintenance, and IoT device management to speed up communicaiton and improve decisions',
                'What is going on in my city?: RAH!s viewed in ACADA enhance visibility and accountability in a single-pane-of-glass',
                'Single Vendor Simplicity: Procuring RAH! in 30 seconds is as easy as it gets.'
        ]
    },
    
    firstBulletACADA: {
        header:  'Monitor, Take Action, Analyze, and Report with ACADA.',
        subHeader:  'End-to-end workflow capabilities on Day 1.',
        bullets: [
                'Monitor your requests, assets, IoT devices, and workflow on one integrated dashboard.',    
                'Take Action with Service Requests. ',
                'Analyze detailed data with our statistics and heatmaps.',
                'Run reports to understand trends.',

        ]
    },
    
   secondBulletACADA: {
        header:  'Monitor Your Requests, Devices, and Workflow for Piece of Mind',
        subHeader:  'Having all of your data on one Dashboard, and in one secure database, is critical for modern business',
        bullets: [
                'Better awareness',    
                'Improved accountability',
                'Centralized communication',
                'Easy prioritization',
        ]
    },
    
    thirdBulletACADA: {
        header:  'Take Action to Solve Your Problem',
        subHeader:  'ACADA Service Request module provides centralized workflow managment in one cloud database',
        bullets: [
                'Streamline Service Requests with auto-populated fields.',    
                'Speed up Service Request solutions with our visual dashboard',
                'Empower your contractors or third parties with access to ACADA',

        ]
    },
  
        fourthBulletACADA: {
        header:  'Analyze Detailed Data for Better Decisions ',
        subHeader:  'ACADA heatmaps provide insight to key geography-based trends',
        bullets: [
                'Easy-to-use GIS based maps and layers',    
                'Analyze historical data within ACADA',
                'Integrated data, all in one source, for a more comprehensive analysis',
        ]
    },

      
        fifthBulletACADA: {
        header:  'Uncover Trends With Our Standard or Customized reports',
        subHeader:  'Reporting helps identify inefficient use of resources or unnecessary delay',
        bullets: [
                'View and analyze reports for better awareness',    
                'Export to Microsoft Excel if 3rd parties need the data',
                'Monitor performance and provide data for better employee coaching',

        ]
    },
    
        firstBulletold: {
        header:  'What’s Going On In My City?',
        subHeader:  'ACADA was designed to answer the question "What is going on in my City?" then solve those issues with workflow for everyday public works activities, and was built on a few key differentiating features:',
        bullets: [
                'RAH! - Our Citizen request application to easily gather issues from the community',
                'Devices which replace repetitive labor-intensive activities of today',
                'Intuitive software designed to function like you work',
                'Goes Beyond "Alerts" With a "Service Request" Function to Take Action',
                'Easy tailoring of KPIs, Thresholds, and Alerts to make ACADA work the way you work'
        ]
    },
    firstBulletsCamp: {
        header:  'What’s Going On Around My Campus?',
        subHeader:  'ACADA was designed to answer the question "What is going on around my campus?" then solve those issues with workflow for everyday maintenance activities, and was built on a few key differentiating features:',
        bullets: [
                'RAH! - Our campus request app to easily gather issues from students, staff, and faculty',
                'Devices which replace repetitive labor-intensive activities of today',
                'Intuitive software designed to function like you work',
                'Goes Beyond "Alerts" With a "Service Request" Function to Take Action',
                'Easy tailoring of KPIs, Thresholds, and Alerts to make ACADA work the way you work'
        ]
    },
    firstBulletspro: {
        header:  'What’s Going On at My Properties?',
        subHeader:  'ACADA was designed to answer the question "What is going on at my Properties?" as well as manage everyday maintenance activity workflow, and was built on a few key differentiating features:',
        bullets: [
                'RAH! - Our tenant request app to easily gather issues from tenants',
                'Devices which replace repetitive labor-intensive activities of today',
                'Intuitive software designed to function like you work',
                'Goes Beyond "Alerts" With a "Service Request" Function to Take Action',
                'Easy tailoring of KPIs, Thresholds, and Alerts to make ACADA work the way you work'
        ]
    },
    secondBulletPoints: {
        header:  'End-to-end Smarter City Solution from One Vendor',
        subHeader:  'ACADA eliminates the need for multiple device vendors, software systems, training and support. In addition, ACADA can:',
        bullets: [
                'Reduce Operational and Capital Expenditure',
                'Improve Staff and Public Safety',
                'Streamline regulatory compliance',
                'Enable Proactive, Data-Driven Planning & Prioritization',
                'Aid in Emergency Planning and Disaster Recovery'
        ]
    },    
    secondBulletRAH: {
        header:  'End-to-end Smarter City Solution from One Vendor',
        subHeader:  'RAH!, when integrated with ACADA, eliminates the need for multiple device vendors, software systems, training and support. In addition, ACADA can:',
        bullets: [
                'Reduce Operational and Capital Expenditure',
                'Improve Staff and Public Safety',
                'Streamline regulatory compliance',
                'Enable Proactive, Data-Driven Planning & Prioritization',
                'Aid in Emergency Planning and Disaster Recovery'
        ]
    },
        secondBulletscamp: {
        header:  'End-to-end Smarter Campus Solution from One Vendor',
        subHeader:  'ACADA eliminates the need for multiple device vendors, software systems, training and support. In addition, ACADA can:',
        bullets: [
                'Reduce Operational and Capital Expenditure',
                'Improve Student, Staff, and Faculty Safety',
                'Streamline regulatory compliance',
                'Enable Proactive, Data-Driven Planning & Prioritization',
                'Aid in Emergency Planning and Disaster Recovery'
        ]
    },
        secondBulletspro: {
        header:  'Smarter Property Management Solution from One Vendor',
        subHeader:  'ACADA integrates your existing solutions and eliminates the future need for multiple device vendors, software systems, training, and support. In addition, ACADA can:',
        bullets: [
                'Reduce Operational and Capital Expenditure',
                'Improve Tenant, Staff, and Public Safety',
                'Streamline regulatory compliance',
                'Enable Proactive, Data-Driven Planning & Prioritization',
                'Aid in Emergency Planning and Disaster Recovery'
        ]
    }
}

export default text;
